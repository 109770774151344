<template>
  <!-- 管理员管理 -->
  <div class="wrapper">
    <!-- <Breadcrumd /> -->

    <div class="header-container">
      <div class="left">
        <el-form inline class="search-header" :model="searchData" label-width="100px">
          <el-form-item label="用户名">
            <el-input style="width:200px" v-model="searchData.userName" placeholder="请输入用户名" size="small"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input style="width:200px" v-model="searchData.mobile" placeholder="请输入手机号" size="small"></el-input>
          </el-form-item>
          <!-- <el-form-item label="角色" v-show="isShow">
        <el-select style="width:200px" v-model="searchData.roleId" placeholder="请选择">
          <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id"> </el-option>
        </el-select>
      </el-form-item> -->
        </el-form>
      </div>
      <div class="right">
        <el-button @click="getAdminList" type="primary" icon="el-icon-search" size="small">查询</el-button>
        <el-button @click="reset" size="small" icon="el-icon-refresh">重置</el-button>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="btn-group">
      <div></div>
      <div>
        <el-button v-show="isShowBtn('UserAdd')" type="primary" icon="el-icon-plus" size="small" @click="$router.push({ name: 'UserAdd' })">添加</el-button>
      </div>
    </div>
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="page_size" />
    <!-- 弹窗 -->
    <!-- <el-dialog title="授权角色" :visible.sync="dialogVisible" width="45%" :show-close="false">
      <el-form ref="form" :model="formData" label-width="100px">
        <el-form-item label="角色">
          <el-select v-model="formData.roleIds" placeholder="请选择" multiple clearable>
            <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" " style="margin-top:40px">
          <el-button type=" " @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveRole">保存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog> -->
  </div>
</template>

<script>
import { getAdminList, removeAdmin, getRoleList, changeStatus } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: '用户名',
    prop: 'userName',
    minWidth:'120',
    customRender(h, row) {
      return <p>{row['userName']}</p>
    }
  },
  {
    label: '角色',
    width: '200',
    prop: 'roleName',
    customRender(h, row) {
      return <p class="one-line">{row['role']?.['roleName']}</p>
    }
  },
  {
    label: '手机号',
    width: '150',
    prop: 'mobile',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['mobile']}>
          {row['mobile']}
        </p>
      )
    }
  },
  {
    label: '状态',
    prop: 'status',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['status']}>
          <a-switch
            disabled={row['isSuper'] == 1}
            default-checked={row['status'] == 1 ? true : false}
            onChange={() => this.changeStatus(row)}
            checked-children="开启"
            un-checked-children="禁用"
          />
        </p>
      )
    }
  },
  {
    label: '创建时间',
    width: '180',
    prop: 'createTime',
    customRender(h, row) {
      return (
        <p class="one-line" title={row['createTime']}>
          {row['createTime']}
        </p>
      )
    }
  },
  {
    label: '操作',
    fixed: 'right',
    width: '200',
    customRender(h, row) {
      return (
        <div>
          <el-link
            v-show={this.isShowBtn('UserEdit')}
            disabled={row['isSuper'] == 1}
            underline={false}
            style="margin-right:20px"
            type="primary"
            onClick={() => this.$router.push({ name: 'UserEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-popconfirm disabled={row['isSuper'] == 1} title={`确定删除吗`} onConfirm={() => this.goRemove(row['id'])}>
            <el-link
              v-show={this.isShowBtn('UserDel')}
              disabled={row['isSuper'] == 1}
              underline={false}
              style="margin-right:20px"
              type="primary"
              slot="reference">
              删除
            </el-link>
          </el-popconfirm>
        </div>
      )
    }
  }
]
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      dialogVisible: false,
      isShow: false,
      list: [],
      page_size: 20,
      searchData: {
        userName: '',
        mobile: ''
        // roleId: ''
      },
      total: 0,
      currentPage: 1,
      columns,
      roleList: []
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getAdminList()
    },
    page_size() {
      this.getAdminList()
    }
  },

  mounted() {
    this.getAdminList()
    this.getRoleList()
  },

  methods: {
    async getRoleList() {
      let params = { page: 1, pageSize: 50 }
      const res = await getRoleList(params)
      this.roleList = res.list || []
    },
    async getAdminList() {
      const { userName, mobile } = this.searchData
      let params = { userName, mobile, page: this.currentPage, pageSize: this.page_size }
      let res = await getAdminList(params)
      this.list = res?.list || []
      this.total = res?.total || 0
    },

    goAdd() {
      this.$router.push({ name: 'MemberDetail' })
    },
    goEdit(id) {
      this.$router.push({ name: 'MemberDetail', params: { id: id || undefined } })
    },
    async goRemove(id) {
      await removeAdmin({ id }).then(() => {
        this.$message.success('删除成功')
        this.getAdminList()
      })
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getAdminList()
    },
    changeStatus(row) {
      const { id, status } = row
      let data = { id, status: status == 1 ? 0 : 1 }
      changeStatus(data).then(() => {
        this.$message.success('操作成功')
        this.getAdminList()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-width: 800px;
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .left {
      flex: 1;
    }
    .right {
      margin-left: 20px;
    }
  }
  .search-header /deep/ {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .arrow {
      text-align: right;
      .open {
        color: #409eff;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
      .updown {
        color: #409eff;
        font-size: 16px;
        transition: all 0.5s;
      }
      .go {
        transform: rotate(-180deg);
      }
    }
    .el-form-item {
      width: 20%;
      min-width: 400px;
      margin-bottom: 0;
      margin-right: 0;
    }
  }
  .el-divider {
    margin: 20px 0;
  }
  .btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
